import React from 'react'

import Welcome from '../Welcome'
import RoomCarousel from '../RoomCarousel'
import Booking from '../Booking'
import Contact from '../Contact'
import styles from './Main.module.scss'

/**
* @author
* @function Main
* */

const Main = () => (
	<div className={styles.mainContainer}>
		<Welcome />
		<RoomCarousel />
		<Booking />
		<Contact />
	</div>
)

export default Main
