import VirusImage from '../assets/img/virus.jpg'
import BridgertonImage from '../assets/img/bridgerton.jpg'

export const topNavigation = {
	menuItems: [
		{ key: 1, target: 'home', label: 'Home' },
		{ key: 2, target: 'rooms', label: 'Szobák' },
		{ key: 3, target: 'book', label: 'Foglalás' },
		{ key: 4, target: 'about', label: 'Rólunk' },
		{ key: 5, target: 'contact', label: 'Kapcsolat' },
		{ key: 6, target: 'https://tiszakaland.lakatalatt.hu', label: 'Tiszakaland', type: 'external' },
	],
	getTopNavigation: () => topNavigation.menuItems,
}

export const escapeRooms = {
	rooms: [
		{ prices: {
			2: [5000, 10000],
			3: [4000, 12000],
			4: [3500, 14000],
			5: [3000, 15000],
			6: [2830, 17000],
			7: [2715, 19000],
			8: [2500, 20000],
		}, key: 1, long: 60, rate: 5, image: VirusImage, name: 'A vírus', value: 'virus', label: 'Vírus', players: 6, hard: 6, description: 'Elindult a világjárvány, csak egy tudós tudja kifejleszteni a megfelelő vakcinát, ami megmentheti az egész világot a haláltól! Zűrzavar, dollár milliók, hatalmi harcok, és egy eltűnt virológus! Vajon sikerült neki elrejteni az illetéktelen kezek elől a vakcinát? Vagy nektek kell a segítségére sietni? Tudjátok 60 perc….és az óra már ketyeg...' },
		{prices: {
			2: [5000, 10000],
			3: [4000, 12000],
			4: [3500, 14000],
			5: [3000, 15000],
			6: [2830, 17000],
			7: [2715, 19000],
			8: [2500, 20000],
		}, key: 2, long: 60, rate: 4, image: BridgertonImage, name: 'Bridgerton', value: 'mozart', label: 'Mozart', players: 4, hard: 8, description: 'Drága Nyájas Olvasó! Lady Whistledown szövevényes feladatokkal, varázslatos helyekre kalauzol! Levelek, amik csak jönnek és jönnek, de vajon meddig? És ki lehet a Lady aki állnéven írja ezeket a leveleket? 60 perced van, hogy kiderítsd! Az óra ketyeg…' },
	],
	getAllRooms: () => escapeRooms.rooms,
}
