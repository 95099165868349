/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import styles from './DifficultyBars.module.scss'

/**
* @author zilahir
* @function DifficultyBars
* */

const HardNessBar = styled.span`
	height: ${props => props.currHardness}%;
	&:before {
		background: ${props => (props.active ? '#ffc654' : '#fff')};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0px;
    width: 4px;
	}
`

const DifficultyBars = props => {
	const { hardness, isHidden } = props
	return (
		<div className={classnames(
			styles.diffBarContainer,
			isHidden ? styles.hidden : null,
		)}
		>
			{
				Array(10).fill().map((currHard, index) => (
					<HardNessBar
						key={index}
						className={styles.hardnessBar}
						currHardness={100 / (10 - index + 1)}
						active={index < hardness}
					/>
				))
			}
		</div>
	)
}

DifficultyBars.defaultProps = {
	isHidden: true,
}

DifficultyBars.propTypes = {
	hardness: PropTypes.number.isRequired,
	isHidden: PropTypes.bool,
}

export default DifficultyBars
