/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import app from 'firebase/app'

import Input from '../common/Input'
import Section from '../common/Section'
import Button from '../common/Button'
import styles from './Contact.module.scss'
import { firebaseConfig } from '../../utils/consts'

/**
* @author
* @function Contact
* */

const Contact = () => {
	const [name, setName] = useState(null)
	const [email, setEmail] = useState(null)
	const [message, setMessage] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

	function sendMessage() {
		alert('lofasz')
	}
	useEffect(() => {
		app.initializeApp(firebaseConfig)
	}, [])
	return (
		<Section
			id="contact"
		>
			<div className={styles.contactContainer}>
				<h1 className={styles.title}>
						Kapcsolat
				</h1>
				{
					!isOpen
						? (
							<div className={styles.onlyButton}>
								<Button
									text="Küldj üzenetet nekünk!"
									onClick={() => setIsOpen(true)}
								/>
							</div>
						)
						: (
							<>
								<form
									method="POST"
									action="https://formsubmit.co/lakatalatt5241@gmail.com"
								>
									<div className={styles.oneContact}>
										<Input
											placeHolder="Név"
											onChange={n => setName(n)}
											name="name"
										/>
									</div>
									<div className={styles.oneContact}>
										<Input
											placeHolder="Email"
											onChange={e => setEmail(e)}
											name="email"
										/>
									</div>
									<div className={styles.oneContact}>
										<Input
											placeHolder="Message"
											onChange={m => setMessage(m)}
											name="details"
										/>
									</div>
									<div className={styles.oneContact}>
										<Button
											onClick={() => null}
											text="Küldés"
											type="submit"
										/>
									</div>
									<input type="hidden" name="_captcha" value="false" />
								</form>
							</>
						)
				}
			</div>
		</Section>
	)
}

export default Contact
