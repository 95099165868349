import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Main from './components/Main'
import { getBgImages } from './store/actions/getBackgroundImages'
import { getRooms } from './store/actions/getRooms'

function App() {
	const dispatch = useDispatch()
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		setIsLoading(true)
		Promise.all([
			dispatch(getBgImages()),
			dispatch(getRooms()),
		]).then(() => {
			setIsLoading(false)
		})
	}, [setIsLoading, dispatch])
	return (
		<div>
			<Main />
		</div>
	)
}

export default App
