import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import classnames from 'classnames'
import { useStore } from 'react-redux'
import styled from 'styled-components'
import Icon from 'react-icons-kit'
import { clock } from 'react-icons-kit/feather'
import { Hidden, Visible } from 'react-grid-system'

import styles from './RoomCarousel.module.scss'
import { carouselSettings, orangeColor } from '../../utils/consts'
import Section from '../common/Section'
import Rate from '../common/Rate'
import DifficultyBars from '../common/DifficultyBars'
import PlayerCounter from '../common/PlayerCounter'
import LightBulb from '../Bulb'
import Gaerson from '../../assets/img/gearson.png'
import MobileRooms from '../MobileRooms'

/**
* @author zilahir
* @function RoomCarousel
* */

const Room = styled.div`
	background-image: url(${props => props.roomImage});
	background-size: cover;
	background-repeat: no-repeat;
	height: 450px;
	background-position: 50% 50%;
`

const RoomCarousel = () => {
	const [rooms, setRooms] = useState([])
	const [activeRoom, setActiveRoom] = useState(1)
	const store = useStore()
	useEffect(() => {
		setRooms(store.getState().rooms.rooms)
	}, [store, rooms])
	function changeRoom(roomNo) {
		setActiveRoom(roomNo)
	}

	return (
		<Section
			className={styles.roomCarouselWrapper}
			image={Gaerson}
			imageAlt="Gaerson"
			id="rooms"
		>
			<LightBulb />
			<h1>Szobáink</h1>
			<h2>Jelenleg 2 szoba közül választhatsz</h2>
			<Hidden xs sm>
				<div className={styles.roomCarousel}>
					<Slider
						dots={false}
						infinite={false}
						speed={carouselSettings.speed}
						slidesToShow={2}
						slidesToScroll={carouselSettings.slidesToScroll}
						slickGoTo={index => changeRoom(index + 1)}
					>
						{
							rooms.map(currRoom => (
								<Room
									className={classnames(
										styles.oneRoom,
										currRoom.key !== activeRoom ? styles.activeRoom : null,
									)}
									roomImage={currRoom.image}
									key={currRoom.key}
									onClick={() => changeRoom(currRoom.key)}
								>
									<p>
										{currRoom.key}
									</p>
									{
										currRoom.key === activeRoom
											? (
												<h1>
													{currRoom.name}
												</h1>
											)
											: null
									}
									<DifficultyBars
										hardness={currRoom.hard}
										isHidden={currRoom.key !== activeRoom}
									/>
								</Room>
							))
						}
					</Slider>
					<div className={styles.roomDetailContainer}>
						<h1 className={styles.roomName}>
							{
								rooms.length ? rooms[activeRoom - 1].name : null
							}
						</h1>
						<p className={styles.desc}>
							{
								rooms.length ? rooms[activeRoom - 1].description : null
							}
						</p>
						<div
							className={styles.metaContainer}
							style={{ color: orangeColor }}
						>
							<div className={styles.oneMeta}>
								<Icon size="2em" icon={clock} />
								<span>
									{
										rooms.length ? `${rooms[activeRoom - 1].long} perc` : null
									}
								</span>
							</div>
							<div className={styles.oneMeta}>
								<PlayerCounter playerCounter={rooms.length ? rooms[activeRoom - 1].players : 0} />
							</div>
							<div className={styles.oneMeta}>
								<Rate rate={rooms.length ? rooms[activeRoom - 1].rate : 0} />
							</div>
						</div>
						<div className={styles.prices}>
							<ul>
								{
									rooms.length > 0 && Object.keys(rooms[activeRoom - 1].prices).map((key, index) => (
										<li key={index}>
											{key} fő esetén: {rooms[activeRoom - 1].prices[key][0]} Ft / fő ({rooms[activeRoom - 1].prices[key][1]} Ft)
										</li>
									))
								}
							</ul>
						</div>
					</div>
				</div>
			</Hidden>
			<Visible xs sm>
				<MobileRooms />
				<div className={styles.prices}>
					<ul>
						{
							rooms.length > 0 && Object.keys(rooms[activeRoom - 1].prices).map((key, index) => (
								<li key={index}>
									{key} fő esetén: {rooms[activeRoom - 1].prices[key][0]} Ft / fő ({rooms[activeRoom - 1].prices[key][1]} Ft)
								</li>
							))
						}
					</ul>
				</div>
			</Visible>
		</Section>
	)
}

export default RoomCarousel
