import { GET_ROOMS } from './actionTypes'
import { escapeRooms } from '../../utils/fakeApi'

export const setRooms = rooms => dispatch => new Promise(resolve => {
	dispatch({
		type: GET_ROOMS,
		payload: {
			rooms,
		},
	})
	resolve()
})

export const getRooms = () => dispatch => new Promise(resolve => {
	dispatch(setRooms(escapeRooms.getAllRooms()))
	resolve(true)
})
