/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import { useStore } from 'react-redux'
import Dropdown from '@zilahir/react-dropdown'
import Icon from 'react-icons-kit'
import { pocket } from 'react-icons-kit/feather/pocket'
import { calendar } from 'react-icons-kit/feather/calendar'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import '@zilahir/react-dropdown/style.css'
import 'rc-time-picker/assets/index.css'

import Section from '../common/Section'
import Button from '../common/Button'
import Input from '../common/Input'
import styles from './Booking.module.scss'
import './booking.scss'
import { orangeColor } from '../../utils/consts'

/**
* @author
* @function Booking
* */

const Booking = () => {
	const [date, setDate] = useState(new Date())
	const [rooms, setRooms] = useState([])
	const [name, setName] = useState(null)
	const [phone, setPhone] = useState(null)
	const [email, setEmail] = useState(null)
	const [selectedRoom, setSelectedRoom] = useState(null)
	const [nextHour, setNextHour] = useState(null)
	const store = useStore()
	useEffect(() => {
		setRooms(store.getState().rooms.rooms)
		const d = new Date()
		d.setMinutes(d.getMinutes() + 30)
		d.setMinutes(0)
		setNextHour(moment(d))
	}, [store, rooms])
	const [isDatePickerVisible, setIsDatePickerVisible] = useState(true)
	function handleDateChange(d) {
		setDate(d)
		setIsDatePickerVisible(false)
	}

	function handleGoback() {
		setIsDatePickerVisible(true)
	}

	return (
		<Section
			className={styles.boookingContainer}
			id="book"
		>
			<div className={styles.innerContainer}>
				<h1 className={styles.title}>
					Foglalás
				</h1>
				<p className={styles.bookingDetails}>
					Foglalás telefonon: +36 20 463 8959
				</p>
				<p className={styles.bookingDetails}>
					vagy az alábbi űrlapon:
				</p>
				{
					isDatePickerVisible
						? (
							<>
								<Dropdown
									onChange={selected => setSelectedRoom(selected)}
									className={styles.dropdown}
									options={rooms}
									value={selectedRoom}
									controlClassName={styles.dropdownControl}
									placeholderClassName={styles.placeholder}
									arrowClassName={styles.arrow}
									placeholder="Válassz szobát..."
								/>
								<Calendar
									onChange={handleDateChange}
									value={date}
									className="lakatalatt-booking"
									defaultView="month"
									tileDisabled={() => !selectedRoom}
									minDate={new Date()}
								/>
							</>
						) : (
							<>
								<div>
									<div
										className={styles.iconContainer}
										style={{ color: orangeColor }}
									>
										<Icon size="2em" icon={pocket} />
										<p className={styles.chosen}>
											{selectedRoom.label}
										</p>
									</div>
								</div>
								<div>
									<div
										className={styles.iconContainer}
										style={{ color: orangeColor }}
									>
										<Icon size="2em" icon={calendar} />
										<p className={styles.chosen}>
											{
												moment(date).format('YYYY-MM-DD')
											}
										</p>
									</div>
								</div>
								<TimePicker
									defaultValue={nextHour}
									showSecond={false}
									minuteStep={30}
									className={styles.timePicker}
									onChange={time => setNextHour(time)}
								/>
								<div className={styles.bookingDetails}>
									<form
										method="POST"
										action="https://formsubmit.co/lakatalatt5241@gmail.com"
									>
										<Input
											placeHolder="Név"
											onChange={n => setName(n)}
											name="name"
										/>
										<Input
											placeHolder="Email"
											onChange={e => setEmail(e)}
											name="email"
										/>
										<Input
											placeHolder="Telefonszám"
											onChange={p => setPhone(p)}
											name="phone"
										/>
										<input type="hidden" name="room" value={selectedRoom.label} />
										<input type="hidden" name="date" value={date} />
										<input type="hidden" name="time" value={`${new Date(nextHour).getHours()}óra : ${new Date(nextHour).getMinutes()}perc`} />
										<input type="hidden" name="_captcha" value="false" />
										<Button
											text="Foglalás"
											onClick={() => null}
											isVisible={!isDatePickerVisible}
											disabled={!name || !phone || !email}
											type="submit"
										/>
									</form>
								</div>
								<div
									className={styles.goBackContainer}
								>
									<button
										onClick={() => handleGoback()}
										type="button"
										onKeyPress={() => null}
										className={styles.back}
									>
										⟵
										<small>
											Vissza
										</small>
									</button>
								</div>
							</>
						)
				}
			</div>
		</Section>
	)
}

export default Booking
