import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import styles from './Section.module.scss'

/**
* @author
* @function Section
* */

const SectionWrapper = styled.div`
	background-color: ${props => props.bgColor};
	background-image: url('${props => props.bgImage}');
`

const Section = props => {
	const { bgColor,
		children,
		className,
		image,
		imageAlt,
		imageSide,
		bgImage,
		refProp,
		id,
	} = props
	return (
		<SectionWrapper
			id={id}
			bgImage={bgImage}
			className={classnames(
				styles.sectionWrapper,
				className,
			)}
			bgColor={bgColor}
			ref={refProp}
		>
			{children}
			{
				image
					? (
						<div className={classnames(
							styles.imageContainer,
							styles[imageSide],
						)}
						>
							<img src={image} alt={imageAlt} />
						</div>
					)
					: null
			}
		</SectionWrapper>
	)
}

Section.defaultProps = {
	bgColor: '#000000',
	bgImage: null,
	className: null,
	id: null,
	image: null,
	imageAlt: null,
	imageSide: 'right',
	refProp: null,
}

Section.propTypes = {
	bgColor: PropTypes.string,
	bgImage: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
	image: PropTypes.string,
	imageAlt: PropTypes.string,
	imageSide: PropTypes.string,
	refProp: PropTypes.objectOf({}),
}
export default Section
