import React from 'react'
import styled from 'styled-components'

import styles from './Bulb.module.scss'
import BulbImage from '../../assets/img/bulb.png'
import BulbGlow from '../../assets/img/bulb-glow.png'

/**
* @author
* @function LightBulb
* */

const Flickering = styled.div`
	background-image: url('${BulbGlow}');
	opacity: 0;
	z-index: 2;
	transition: all .8s ease;
	position: absolute;
	bottom: -710px;
	left: 20px;
	width: 350px;
	height: 700px;
	background-repeat: no-repeat;
`

const Bulb = styled.div`
	background-image: url('${BulbImage}');
	transition: all .8s ease;
	position: absolute;
	bottom: -710px;
	right: 0;
	width: 350px;
	height: 700px;
	z-index: 1;
	animation-iteration-count: infinite;
	background-repeat: no-repeat;
`

const LightBulb = () => (
	<div className={styles.bulbContainer}>
		<Flickering className={styles.flickering} />
		<Bulb className={styles.bulb} />
	</div>
)

export default LightBulb
