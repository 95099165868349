import { GET_ROOMS } from '../actions/actionTypes'

const initialState = {
	rooms: [],
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_ROOMS:
		return {
			...state,
			rooms: action.payload.rooms,
		}
	default:
		return state
	}
}

export default reducer
