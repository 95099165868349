import React from 'react'
import Slider from 'react-slick'
import { useStore } from 'react-redux'
import styled from 'styled-components'
import Countdown from 'react-countdown'

import { carouselSettings } from '../../utils/consts'
import styles from './Welcome.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Navigation from '../Navigation'
import TopHeader from '../TopHeader'

/**
* @author
* @function Welcome
* */

const Image = styled.div`
	background-image: url(${props => props.image});
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
`

const Welcome = () => {
	const store = useStore()
	const { bgImages } = store.getState().bgImages
	function renderCountDown(payload) {
		return (
			<span>{payload.minutes}:{payload.seconds}</span>
		)
	}
	return (
		<div
			className={styles.welcomeContainer}
			id="home"
		>
			<TopHeader />
			<Navigation />
			<div className={styles.welcomeTextContainer}>
				<h1>
					60 perced van hogy kiszabadulj. <br />
					Neked sikerülne?
				</h1>
				<Countdown
					date={Date.now() + 3599000}
					renderer={renderCountDown}
				/>
			</div>
			<Slider
				dots={carouselSettings.dots}
				infinite={carouselSettings.infinite}
				speed={carouselSettings.speed}
				slidesToShow={carouselSettings.slidesToShow}
				slidesToScroll={carouselSettings.slidesToScroll}
				autoPlay={carouselSettings.autoPlay}
			>
				{
					bgImages.map(currBgImage => (
						<Image key={currBgImage.id} image={`https://farm${currBgImage.farm}.staticflickr.com/${currBgImage.server}/${currBgImage.id}_${currBgImage.secret}_b.jpg`} />
					))
				}
			</Slider>
		</div>
	)
}

export default Welcome
