import React from 'react'
import PropTypes from 'prop-types'

import styles from './Input.module.scss'

/**
* @author
* @function Input
* */

const Input = props => {
	const { placeHolder, onChange, name } = props
	return (
		<div className={styles.inputContainer}>
			<input
				type="text"
				placeHolder={placeHolder}
				onChange={onChange}
				name={name}
			/>
		</div>
	)
}

Input.defaultProps = {
	name: null,
}

Input.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeHolder: PropTypes.string.isRequired,
}

export default Input
