export const carouselSettings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoPlay: true,
}

export const orangeColor = '#ffc654'

export const firebaseConfig = {
	apiKey: 'AIzaSyAh9Ga2ZJrMOxnNgYUgLcfgj2SPqWWDKag',
	authDomain: 'lakatalatt-1501676355161.firebaseapp.com',
	databaseURL: 'https://lakatalatt-1501676355161.firebaseio.com',
	projectId: 'lakatalatt-1501676355161',
	storageBucket: 'lakatalatt-1501676355161.appspot.com',
	messagingSenderId: '214890059574',
	appId: '1:214890059574:web:26d9e3ec5f356cc9380597',
	measurementId: 'G-NDP70KVMFR',
}
