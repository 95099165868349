/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'react-icons-kit'
import { user } from 'react-icons-kit/feather'

import { orangeColor } from '../../../utils/consts'

/**
* @author zilahir
* @function PlayerCounter
* */

const PlayerCounter = props => {
	const { playerCounter, size, className } = props

	return (
		<div
			style={{ color: orangeColor }}
			className={className}
		>
			{
				Array(playerCounter).fill().map((curr, index) => (
					<Icon key={index} size={`${size}em`} icon={user} />
				))
			}
		</div>
	)
}

PlayerCounter.defaultProps = {
	className: null,
	size: 2,
}

PlayerCounter.propTypes = {
	className: PropTypes.string,
	playerCounter: PropTypes.number.isRequired,
	size: PropTypes.number,
}

export default PlayerCounter
