import React from 'react'
import Icon from 'react-icons-kit'
import { mapPin } from 'react-icons-kit/feather/mapPin'
import { phone } from 'react-icons-kit/feather/phone'

import styles from './TopHeader.module.scss'

/**
* @author
* @function TopHeader
* */

const TopHeader = () => (
	<div className={styles.topHeaderContainer}>
		<div
			style={{
				color: '#000',
				marginRight: '10px',
			}}
		>
			<Icon size="2em" icon={mapPin} />
		</div>
		<p>
			5241, Abádszalók István Király út 11
		</p>
		<div
			style={{
				marginRight: '10px',
			}}
		>
			<Icon size="2em" icon={phone} />
		</div>
		<p>
			+36 20 463 8959
		</p>
	</div>
)

export default TopHeader
