/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'

import styles from './Button.module.scss'

/**
* @author
* @function Buttoon
* */

const Button = props => {
	const { onClick, text, isVisible, disabled, type } = props
	return (
		<>
			{
				isVisible
					? (
						<div className={styles.buttonContainer}>
							<button
								type={type}
								className={styles.button}
								onClick={onClick}
								disabled={disabled}
							>
								{
									text
								}
							</button>
						</div>
					) : null
			}
		</>
	)
}

Button.defaultProps = {
	disabled: false,
	isVisible: true,
	type: 'button',
}

Button.propTypes = {
	disabled: PropTypes.bool,
	isVisible: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	type: PropTypes.string,
}

export default Button
