/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'react-icons-kit'
import { star } from 'react-icons-kit/feather'
import styled from 'styled-components'

import { orangeColor } from '../../../utils/consts'

/**
* @author zilahir
* @function PlayerCounter
* */

const RateIcon = styled.div`
    display: block;
    svg {
        fill: ${props => props.fill};
    }
`

const Rate = props => {
	const { rate } = props

	return (
		<div style={{ color: orangeColor, display: 'flex' }}>
			{
				Array(5).fill().map((curr, index) => (
					<RateIcon key={index} fill={index < rate ? orangeColor : null}>
						<Icon size="2em" icon={star} />
					</RateIcon>
				))
			}
		</div>
	)
}

Rate.propTypes = {
	rate: PropTypes.number.isRequired,
}

export default Rate
