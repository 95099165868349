import React, { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import styled from 'styled-components'
import Icon from 'react-icons-kit'
import { clock } from 'react-icons-kit/feather'

import DifficultyBars from '../common/DifficultyBars'
import PlayerCounter from '../common/PlayerCounter'
import styles from './MobileRooms.module.scss'
import { orangeColor } from '../../utils/consts'

/**
* @author zilahir
* @function MobileRooms
* */

const Room = styled.div`
	background-image: url(${props => props.roomImage});
	background-size: cover;
	background-repeat: no-repeat;
	height: 450px;
	background-position: 50% 50%;
`

const MobileRooms = () => {
	const store = useStore()
	const [rooms, setRooms] = useState([])
	useEffect(() => {
		setRooms(store.getState().rooms.rooms)
	}, [store, rooms])
	return (
		<div className={styles.mobileRoomsContainer}>
			{
				rooms.map(currRoom => (
					<Room
						className={styles.oneRoom}
						roomImage={currRoom.image}
						key={currRoom.key}
					>
						<h1>
							{currRoom.name}
						</h1>
						<p>
							{currRoom.description}
						</p>
						<div
							style={{ color: orangeColor }}
							className={styles.metaContainer}
						>
							<div className={styles.icon}>
								<Icon size="1em" icon={clock} /> <span>60 perc</span>
							</div>
							<PlayerCounter
								playerCounter={rooms.length ? currRoom.players : 0}
								size={1}
								className={styles.playerCounter}
							/>
							<DifficultyBars
								hardness={currRoom.hard}
								isHidden={false}
							/>
						</div>
					</Room>
				))
			}
		</div>
	)
}

export default MobileRooms
