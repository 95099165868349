/* eslint-disable no-alert */
import React from 'react'

import { topNavigation } from '../../utils/fakeApi'
import styles from './Navigation.module.scss'

/**
* @author
* @function Navigation
* */

const Navigation = () => {
	function handleClick(event, e, type) {
		if (type !== 'external') {
			event.preventDefault()
			const scrollTo = document.getElementById(e)
			window.scrollTo({ behavior: 'smooth', top: scrollTo.offsetTop })
		}
	}
	return (
		<ul className={styles.navigation}>
			{
				topNavigation.getTopNavigation().map(currNavItem => (
					<li
						className={styles.navItem}
						key={currNavItem.target}
					>
						<a
							target={currNavItem.type === 'external' ? '_blank' : '_self'}
							href={currNavItem.target}
							onClick={e => handleClick(e, currNavItem.target, currNavItem.type)}
							rel="noreferrer"
						>
							{currNavItem.label}
						</a>
					</li>
				))
			}
		</ul>
	)
}

export default Navigation
